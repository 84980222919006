.historyImageSection{
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #FEFAF1;
    padding-top: 100px;
}

.historyImageSection img{
    width: 100%;
    height: auto;

}

.historyImageSection .textContainer{
    padding:    0px 10px;
}
.historyImageSection h2{
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
    margin-bottom: 20px;
}

.historyImageSection .text{
    font-family: 'calsonpro';
    font-size: 15px;
    letter-spacing: 0.06rem;
    line-height: 18px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 60px;
}

.historyImageSection .title{
    font-family: 'calsonproitalic';
    font-size: 19px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    margin: 0px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}