.footerContainer {
    height: 100vh;
    background-color: #FEFAF1;
}


.footerContainer a {
    color: black;
    text-decoration: none;
}

.footerContainera:hover {
    cursor: pointer;
}

.imageContainer {
    width: 100%;
    display: flex;
}

.imageContainer img {
    width: 50%;
    height: auto;
}

.footerContentContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    width: calc(100% - 40px);
    background-color: #FEFAF1;
    padding: 20px;
    gap: 20px;
}

.footerContentContainer p {
    margin: 0px;
}

.languefooter {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 20px;
}

.languefooter button {
    font-family: 'gustavo';
    font-size: 14px;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    border: none;
    /* Enlève la bordure par défaut */
    background: transparent;
    /* Arrière-plan transparent */
    cursor: pointer;
    color: black;
    transition-duration: 300ms;
}

.languefooter button.active {
    border-bottom: 1px solid black;
    /* Bordure noire pour le bouton actif */
}

.languefooter button:hover {
    border-bottom: 1px solid black;
}

.languefooter button:focus {
    border-bottom: 1px solid black;
}


.footerContainer button {
    text-decoration: none;
    font-family: 'gustavo';
    font-size: 16px;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: black;
}

.footerContainer .confirmatiom {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
}

.footerContentContainer .ligne1,
.footerContentContainer .ligne2,
.footerContentContainer .ligne3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'gustavo';
    font-size: 16px;
    letter-spacing: 0.01rem;
    text-transform: uppercase;
    color: black;
}

.newsletter {
    display: flex;
    align-items: flex-end;
    gap: 10px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.newsletter p {
    line-height: 11px;
}

input {
    all: unset;
    border-bottom: 1px solid black;
    height: 15px;
    width: 300px;
}

.footerContainer .confirmation {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    /* Centrer le texte */
}

.newsletter p:hover, .ligne1 p:hover, .ligne2 p:hover, .ligne3 p:hover {
    cursor: pointer;
}

@media screen and (max-width:1600px) {

    .footerContentContainer .ligne1,
    .footerContentContainer .ligne2,
    .footerContentContainer .ligne3 {
        font-size: 14px;
    }

    .footerContainer button {
        font-size: 14px;
    }

}

@media screen and (max-width:1300px) {

    .footerContentContainer .ligne1,
    .footerContentContainer .ligne2,
    .footerContentContainer .ligne3 {
        font-size: 12px;
    }

    .footerContainer button {
        font-size: 12px;
    }

}