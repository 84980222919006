/* Ajoutez ces lignes dans votre CSS principal ou le fichier de style du composant */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

/* Styles pour la section de la liste d'hôtels */
.hotelListSection2 {
    height: calc(100vh - 100px) !important;
    flex-direction: column;
    justify-content: flex-start;
    margin: auto 10px;
    align-items: initial;
    padding-top: 100px;
}

.subTitleHotelList2 {
    margin: 0px;
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    margin-bottom: 80px;
}

.hotelListContainer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    position: absolute;
    top: 44%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);

}

.hotelListContainer2 a {
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
}

.smallHotelListContainer2 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.hotelListContainer2 p {
    margin: 0px;
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    text-align: center;
}

/* Styles pour le carrousel */
.carouselOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carouselContainer {
    position: relative;
    width: 100%;
    max-width: 900px;
    height: auto;
    overflow: hidden;
    margin: 0px 10px;
}

.carouselSlider img {
    width: 100%;
    height: auto;
}

/* Assurez-vous que les boutons de défilement sont bien positionnés */
.slick-prev:before, .slick-next:before {
    content: none;
}

.slick-prev, .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
}