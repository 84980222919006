.superContainerMentions{
    margin-top: 100px;
    background-color: #FEFAF1;
    height: 100vh;
}

.superContainerMentions h1{
    font-family: 'gustavoreg';
    font-size: 17px;
    letter-spacing: .03rem;
    margin-bottom: 24px;
    text-transform: uppercase;
    text-align: center;
}

.superContainerMentions p{
    font-family: 'calsonpro';
    font-size: 18px;
    letter-spacing: .02rem;
    line-height: 24px;
    margin: 10px 0;
    text-align: justify;
    text-align: center;
    width: 80%;
    margin: auto;
}