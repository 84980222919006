.footerMobileBigContainer {
    height: calc(100vh - 30px);
    background-color: #FEFAF1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 100px 10px 30px;
}

.imgFooterMobile {
    width: 100%;
    height: auto;
}

.contentFooterMobileContainer {
    display: flex;
    flex-direction: column;
}

.ligne1, .ligne2, .ligne3, .ligne4 {
    display: flex;
    justify-content: space-between;
}

.contentFooterMobileContainer p, .contentFooterMobileContainer a, .contentFooterMobileContainer button {
    font-family: 'gustavo';
    font-size: 12px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
}

.contentFooterMobileContainer a {
    color: black;
    text-decoration: none;
    margin: 10px 0px;
}

.langueMobileFooter {
    display: flex;
    gap: 20px;
}

.langueMobileFooter button {
    color: black;
    border: none;
    text-decoration: none;

}

.langueMobileFooter button.active {
    text-decoration: underline;
}

.ligne1 form {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    margin: 12px 0px;
}

.contentFooterMobileContainer input {
    width: 200px;
    font-family: 'gustavo';
    font-size: 12px;
    letter-spacing: 0.02rem;
}

.contentFooterMobileContainer input::placeholder {
    font-family: 'gustavo';
    font-size: 12px;
    color: #888;
    letter-spacing: 0.02rem;
}

.confirmationMessage {
    margin: 0px;
    font-family: 'gustavo';
    font-size: 12px;
    text-transform: uppercase;
}

.confirmationMessage p{
    margin: 0px;    
}

form button{
    color: black;
}