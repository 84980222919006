.superContainer {
    min-height: calc(100vh - 108px);
    display: flex;
    align-items: flex-end;
    background-color: #FEFAF1;
    padding-top: 108px;
}

.historyContainer {
    display: flex;
    padding: 0px 20px 20px;
    gap: 20px;
    height: auto;
    /* Assurez-vous que le conteneur occupe toute la hauteur disponible */
}

.historyContainer .leftPart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68%;
}

.historyContainer .leftPart .textContainer h1,
h2 {
    font-family: 'gustavoreg';
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    font-size: 17px;
    margin: 0px;
    margin-bottom: 20px;
}

.historyContainer .leftPart .firstBlockText,
.historyContainer .leftPart .secondBlockText {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;

}

.historyContainer .leftPart .firstBlockText {
    margin-bottom: 80px;
}


.historyContainer .leftPart .firstBlockText p {
    font-family: 'calsonpro';
    letter-spacing: 0.02rem;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
}

.historyContainer .leftPart .secondBlockText p {
    font-family: 'calsonproitalic';
    letter-spacing: 0.02rem;
    font-size: 18px;
    line-height: 22px;
    margin: 0px;
    font-style: italic;
}

.historyContainer .rightPart {
    display: flex;

    justify-content: flex-end;
    /* Centrer l'image horizontalement */
    align-items: flex-start;
    /* Centrer l'image verticalement */
    width: 40%;
    /* Ajustez si nécessaire */
}

.historyContainer .rightPart img {
    width: fit-content;
    max-width: 100%;
    max-height: calc(100vh - 127px);
    height: auto;
}

@media screen and (max-width:1600px) {

    .historyContainer .leftPart h1,
    .historyContainer .leftPart h2 {
        font-size: 14px !important;
    }

    .historyContainer .leftPart .firstBlockText p {
        font-size: 16px;
    }

    .historyContainer .leftPart .secondBlockText p {
        font-size: 16px;
    }
}

@media screen and (max-width:1300px) {

    .historyContainer .leftPart h1,
    .historyContainer .leftPart h2 {
        font-size: 12px !important;
    }

    .historyContainer .leftPart .firstBlockText p {
        font-size: 14px;
    }

    .historyContainer .leftPart .secondBlockText p {
        font-size: 14px;
    }
}


@media screen and (max-height:800px) {
    .historyContainer .leftPart {
        width: 80%;
    }
}