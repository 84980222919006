.historyLandingMobile{
    background-color: #FEFAF1;
    height: 100vh;
    padding: 100px 10px 0px;
}

.historyLandingMobile h1{
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
    margin-bottom: 20px;
}

.historyLandingMobile p{
    font-family: 'calsonpro';
    font-size: 15px;
    letter-spacing: 0.06rem;
    line-height: 18px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 50px;
}

.historyLandingGif{
    width: 100%;
}
