.actualityLandingContainer {
    min-height: calc(100vh - 127px);
    background-color: #FCFAF4;
    display: flex;
    align-items: flex-end;
    padding: 0px 20px 20px;
    padding-top: 108px;
}

.actualityLandingSmallContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
} 

.separateContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 64%;
}

.actualityLandingTextBlock h1,
.actualityLandingTextBlock h2 {
    font-family: 'gustavoreg';
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    font-size: 17px;
    margin-bottom: 24px;
}

.actualityLandingTextBlock h2 {
    width: fit-content;
    margin-bottom: 10px;
}

.actualityLandingTextBlock p,
.actualityLandingUniqueContainer p {
    font-family: 'calsonpro';
    letter-spacing: 0.02rem;
    font-size: 18px;
    line-height: 22px;
    margin: 0px 0px;
    margin-bottom: 54px;
    width: 90%;
}

.actualityLandingUniqueContainer {
    position: relative;
    color: black;
    text-decoration: none;
    /* Ajouté pour s'assurer que le h2 est correctement positionné */
}

.actualityLandingUniqueContainer:hover {
    cursor: pointer;
}

.actualityLandingUniqueContainer:hover h2 {
    text-decoration: underline;
    cursor: pointer;
    /* Indique que c'est cliquable */
    transition-duration: 300ms;
    /* Ajoute une transition douce */
}

.testactuality {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 37%;
    height: 100%;
}

.testactuality img {
    max-width: 100%;
    max-height: calc(100vh - 127px);
    height: auto;
    width: fit-content;
}

.actualityLandingActualiteContainer {
    height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    direction: rtl;
    margin-top: 15px;
}

.scrollableContent {
    padding-left: 10px;
    direction: ltr;
}

.actualityLandingActualiteContainer::-webkit-scrollbar {
    width: 2px;
    height: 30px;
}

.actualityLandingActualiteContainer::-webkit-scrollbar-thumb {
    background-color: black;
    border-radius: 2px;
}

.actualityLandingActualiteContainer::-webkit-scrollbar-thumb:hover {
    background-color: black;
}

.actualityLandingUniqueContainer .date {
    margin-bottom: 24px;
    font-family: 'calsonproitalic';
    font-size: 14px;
}

.actualityLandingTextBlock .subTexte {
    font-family: 'calsonprobolditalic';
    text-align: right;
    margin-top: 24px;
}

.actualityLandingContainer h2 {
    width: fit-content;
    margin-bottom: 4px;
}



@media screen and (max-width: 1600px) {

    .actualityLandingContainer h1,
    .actualityLandingContainer h2 {
        font-size: 14px;
    }

    .actualityLandingContainer p {
        font-size: 16px;
        width: 80%;
    }
}

@media screen and (max-width: 1300px) {

    .actualityLandingContainer h1,
    .actualityLandingContainer h2 {
        font-size: 12px;
    }

    .actualityLandingContainer p {
        font-size: 14px;
    }
}

@media screen and (max-height: 800px) {
    .actualityLandingContainer .separateContainer {
        width: 80%;
    }
}