.reserve-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: none;
  border-top: 1px solid #ddd;
  padding: 16px;
  font-size: 16px;
  font-family: 'gustavo';
  letter-spacing: 0.02rem;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
  background-color: #FEFAF1;
  border-top: 1px solid black;
  opacity: 0;
  transition: opacity 1s ease-in-out, background-color 0.3s ease;
  display: none;
  color: black;
}

.reserve-button.show {
  opacity: 1;
}

.reserve-button.hidden {
  opacity: 0;
}

.reserve-button.issou {
  color: black !important;
  border-top: 1px solid black !important;
  background-color: #FEFAF1 !important;
}

.reserve-button svg {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.reserve-button.landing {
  background-color: transparent;
  color: white;
  border-top: 1px solid white;
}


.reserve-button.landing svg {
  opacity: 1;
}

.reserve-button.hidden {
  display: none;
}

/* Responsiveness */
@media (max-width: 1024px) {
  .reserve-button {
    display: block;
  }
}