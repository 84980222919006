.bigCarouselSpotifyLanding {
    height: 100vh;
    background-color: #FEFAF1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0vw;
    /* Ajoute un padding à gauche et à droite pour créer de l'espace */
}

.bigCarouselSpotifyLanding .slick-slider {
    display: flex;
    align-items: center;
    width: 100%;
    /* Le slider occupe toute la largeur de son conteneur */
}

.bigCarouselSpotifyLanding .slick-track {
    display: flex;
    justify-content: center;
}

.bigCarouselSpotifyLanding .slick-slide {
    margin: 0 20px;
    /* Espacement pour les éléments adjacents */
    display: flex;
    align-items: center;
    justify-content: center;
}

.bigCarouselSpotifyLanding .slide {
    width: 100%;
    /* L'élément prend toute la largeur disponible */
    height: 60vh;
    /* La hauteur est fixe, tu peux ajuster cette valeur */
}

.zizoulamoula {
    display: flex;
    justify-content: center;
    width: 100%;
}

div:has(.zizoulamoula) {

    width: 100%;
}

.slick-dots {
    bottom: -10%;
    text-align: center;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots button {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ccc;
    border: none;
    outline: none;
}

.slick-dots .slick-active button {
    background: #000;
}

.bigCarouselSpotifyLanding p {
    text-align: center;
    font-family: 'calsonpro';
    letter-spacing: 0.04rem;
    font-size: 16px;
    line-height: 24px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    margin: 0px;
}

.slick-dots li button:before {
    line-height: 10px !important;
    width: 10px !important;
    height: 10px !important;
}

.slick-dots li button {
    width: 10px !important;
    height: 10px !important;
}

@media screen and (max-height: 590px) {
    .bigCarouselSpotifyLanding .slide {
        height: 65vh !important;
    }
}