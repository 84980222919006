.section2LandingMobile{
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    margin: 0px 10px;
    padding-top: 100px;
}
.section2LandingMobile .textContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.section2LandingMobile .textContainer p{
    font-family: 'calsonpro';
    font-size: 15px;
    letter-spacing: 0.06rem;
    line-height: 18px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 30px;
}

.section2LandingMobile .textContainer .title{
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    margin: 0px;
    
}

.section2LandingMobile .imgMobile{
    width: 50%;
    height: auto;
    align-self: flex-end;
}