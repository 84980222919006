.carouselHotelsLanding {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: flex-end;
    background-color: #FEFAF1;
}

.carousel-inner.paused {
    animation-play-state: paused; /* Met l'animation en pause */
}

.carousel-inner {
    display: flex;
    width: 100%; /* La largeur totale est calculée par le style inline */
    animation: scroll 60s linear infinite; /* Animation fluide */
}

.carousel-item {
    flex: 0 0 auto;
    width: 439px !important; /* Ajustez en fonction du nombre d'éléments visibles */
    box-sizing: border-box;
    margin: 0px 10px;
    transition: opacity 0.5s ease;
}

.carousel-item.hidden {
    opacity: 0;
    pointer-events: none; /* Pour désactiver les événements sur les éléments masqués */
}

img {
    width: 100%;
    height: auto;
}

.feuillet {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
}

.top {
    text-align: left;
}

.top .title {
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    font-size: 15px;
    margin: 0px;
}

.top .address {
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 15px;
    font-style: italic;
    margin: 10px 0px;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #FEFAF1;
    width: 70%;
    padding: 15px;
}

.bottom p {
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.01rem;
    font-size: 14px;
}

.bottom .texte {
    font-family: 'calsonpro';
    letter-spacing: 0.02rem;
    font-size: 16px;
    line-height: 22px;
    text-transform: initial;
    margin: 10px 0px;
}

.bottom .voyager {
    text-align: right;
}

.carouselHotelsLanding a {
    color: black;
    text-decoration: none;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%);
    }
}


@media screen and (max-height: 800px){
    .carousel-item {
        width: 390px !important;
    }
}

@media screen and (max-width: 1600px){
    .top .title{
        font-size: 14px;
    }
    .top .address{
        font-size: 16px;
    }

    .bottom p{
        font-size: 13px;
    }

    .bottom .texte{
        font-size: 15px;
    }

}

@media screen and (max-height: 750px){
    .carousel-item {
        width: 380px !important;
    }
}

@media screen and (max-height: 710px){
    .carousel-item {
        width: 370px !important;
    }
}

@media screen and (max-height: 700px){
    .carousel-item {
        width: 360px !important;
    }
}

@media screen and (max-height: 690px){
    .carousel-item {
        width: 350px !important;
    }
}

@media screen and (max-height: 680px){
    .carousel-item {
        width: 340px !important;
    }
}

@media screen and (max-height: 660px){
    .carousel-item {
        width: 330px !important;
    }
}


@media screen and (max-height: 640px){
    .carousel-item {
        width: 315px !important;
    }
}


@media screen and (max-height: 630px){
    .carousel-item {
        width: 310px !important;
    }
}

@media screen and (max-width: 1300px){
    .top .title{
        font-size: 12px;
    }

    .top .address{
        font-size: 14px;
    }

    .bottom p{
        font-size: 12px;
    }

    .bottom .texte{
        font-size: 14px;
    }
}
