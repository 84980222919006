.ultraContainerTextBlock {
    background-color: #FEFAF1;
    min-height: calc(100vh - 108px);
    display: flex;
    align-items: flex-end;
    padding-top: 108px;
}

.text-block {
    width: calc(100% - 40px);
    height: fit-content;
    display: flex;
    background-color: #FEFAF1;
    padding: 0px 20px 20px 20px;
}

.bigContainerTextblock {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 68%;
    height: calc(100vh - 128px);
}

.bigContainerTextblock:hover {
    cursor: pointer;
}

.containerTextblock {
    width: 77%;
    display: flex;
    flex-direction: column;
}

.containerTextblock:hover {
    cursor: pointer;
}

.containerTextblock h2 {
    font-family: 'gustavoreg';
    text-transform: uppercase;
    letter-spacing: rem;
    font-size: 17px;
    margin-bottom: 24px;
}

.containerTextblock p {
    font-family: 'calsonpro';
    letter-spacing: 0.02rem;
    font-size: 18px;
    line-height: 24px;
    text-align: justify;
    margin: 10px 0px;
}

.fixButton {
    display: flex;
    flex-direction: column;
}

.buttonCarousel {
    width: 77%;
    display: flex;
    justify-content: flex-end;
}

.buttonCarousel:hover {
    cursor: pointer;
}

.buttonCarousel p {
    font-family: "calsonbold";
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 18px;
}

.carousel {
    position: relative;
    width: 100%;
    height: 305px; /* Ajuste selon la taille de tes images */
    overflow-x: hidden;
}

.carousel-image {
    position: absolute;
    bottom: 0;
    width: calc(20% - 20px); /* Réduit la largeur pour compenser l'espace de la marge */
    height: auto;
    transition-property: opacity, transform;
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
    margin-right: 20px; /* Ajoute une marge entre les images */
}

.position-0 {
    left: 0;
    z-index: 5;
    transition-duration: 500ms;
}

.position-1 {
    transform: translateX(calc(100% + 20px));
    z-index: 4;
    transition-duration: 500ms;
}

.position-2 {
    transform: translateX(calc(200% + 40px));
    z-index: 3;
    transition-duration: 500ms;
}

.position-3 {
    transform: translateX(calc(300% + 60px));
    z-index: 2;
    transition-duration: 500ms;
}

.position-4 {
    transform: translateX(calc(400% + 80px));
    z-index: 1;
    transition-duration: 500ms;
}

/* Nouvelle classe pour position-5 */
.position-5 {
    transform: translateX(400%); /* Reste en place initialement */
    opacity: 0; /* Transition vers l'opacité 0 */
    z-index: 0;
    transition: opacity 500ms ease-in-out, transform 0s 500ms; /* D'abord opacité, puis bouge hors de l'écran */
}

.position-6,
.position-7,
.position-8,
.position-9,
.position-10,
.position-11,
.position-12,
.position-13,
.position-14 {
    left: 100%;
    opacity: 0;
    z-index: 0;
    transform: translateX(0%);
    transition-duration: 500ms;
}

.test {
    width: 93%;
    display: flex;
    position: absolute;
    right: 20px;
    bottom: 20px;
    justify-content: space-between;
}

.carousselSecond {
    position: relative;
    width: 40%; /* ou une autre valeur selon votre design */
    min-height: 300px; /* Valeur initiale, ajustez si nécessaire */
    display: flex;
    flex-direction: column;
    overflow: hidden; /* Cache les débordements */
    height: unset !important;
    border: 2px solid black;
}


.carousselSecond:hover{
    cursor: pointer;
}

.carousselSecond .image-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
}

.second-carousel-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%; /* Assurez-vous que l'image occupe toute la largeur */
    height: 100%; /* Assurez-vous que l'image occupe toute la hauteur */
    object-fit: cover; /* Garde le ratio d'aspect et crop si nécessaire */
    opacity: 0;
    transition: opacity 500ms ease-in-out;
}

.second-carousel-image.visible {
    opacity: 1;
}

.adressesHotels {
    font-family: "calsonpro";
    color: white;
    letter-spacing: 0.06rem;
    font-size: 18px;
    margin: 0px;
    text-decoration: none;
}

.trip {
    font-family: "gustavo";
    color: white;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    border-bottom: 1px solid white;
    margin: 0px;
    text-decoration: none;
}

@media screen and (max-width: 1600px) {
    .carousel {
        height: 250px;
    }

    .containerTextblock h2 {
        font-size: 14px;
    }

    .containerTextblock p {
        font-size: 16px;
    }

    .buttonCarousel p {
        font-size: 16px;
    }

    .adressesHotels {
        font-size: 16px;
    }

    .trip {
        font-size: 14px;
    }
}

@media screen and (max-width: 1875px) {
    .carousel {
        height: 288px;
    }
}

@media screen and (max-width: 1800px) {
    .carousel {
        height: 275px;
    }
}

@media screen and (max-width: 1700px) {
    .carousel {
        height: 258px;
    }
}

@media screen and (max-width: 1300px) {

    .containerTextblock h2 {
        font-size: 12px;
    }

    .containerTextblock p {
        font-size: 14px;
    }

    .buttonCarousel p {
        font-size: 14px;
    }

    .adressesHotels {
        font-size: 14px;
    }

    .trip {
        font-size: 12px;
    }
}
