.superContainerMobileSpotify {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FCFAF4;
    height: calc(100vh - 100px);
    padding-top: 100px;
    overflow: hidden; /* Cache le débordement */
}

.superContainerMobileSpotify .slick-slider{
    width: 95%;
}

.superContainerMobileSpotify .slick-prev,
.superContainerMobileSpotify .slick-next{
    top: -9%;
}

.subspotify{
    text-align: left !important;
}

.superContainerMobileSpotify h1 {
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
    margin: 10px 10px 25px;
    width: calc(100% - 20px);
    text-align: left;
}

.superContainerMobileSpotify p {
    font-family: 'calsonpro';
    font-size: 15px;
    letter-spacing: 0.06rem;
    line-height: 18px;
    margin: 0px 10px;
    margin-bottom: 60px;
    text-align: center; /* Centre le texte */
}

.iframeContainer {
    display: flex;
    justify-content: center; /* Centre l'iframe */
}

.iframe {
    width: 100%; /* Largeur de chaque iframe */
    height: 450px; /* Hauteur fixe pour l'iframe */
}

.slick-arrow {
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

.superContainerMobileSpotify .slick-list{
    overflow: visible;
    width: 80%;
    margin: auto;
    
}

.superContainerMobileSpotify .slick-slide{
    display: flex;
    justify-content: center;
}
