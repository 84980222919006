.historySectionTexte {
    height: calc(100vh - 100px);
    padding: 100px 10px 0px;
    background-color: #FEFAF1;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
}

.historySectionTexte h1{
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
    margin-bottom: 20px;
}

.historySectionTexte p{
    font-family: 'calsonproitalic';
    font-size: 15px;
    letter-spacing: 0.06rem;
    line-height: 18px;
    margin: 0px;
    text-align: justify;
    margin-bottom: 20px;
    font-style: italic;
}

.historySectionTexte .imgContainer{
    align-self: flex-end;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
}

.historySectionTexte .imgContainer img{
    align-self: flex-end;
    width: 40%;
    height: auto;
}