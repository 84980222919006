/* Ajoutez ces lignes dans votre CSS principal ou le fichier de style du composant */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';



/* Styles pour la section de la liste d'hôtels */
.hotelListSection {
    height: calc(100vh - 100px);
    flex-direction: column;
    justify-content: flex-start;
    margin: 0px 10px;
    align-items: initial;
    padding-top: 100px;
}

.subTitleHotelList {
    margin: 0px;
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    margin-bottom: 65px;
}

.hotelListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    margin-bottom: 65px;
    width: 100%;
}

.hotelListSection .carouselOverlay{
  top: 10px;
}

.hotelListContainer a {
    text-decoration: none;
    color: black;
    font-family: 'gustavo';
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 15px;
}

.smallHotelListContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.hotelListContainer p {
    margin: 0px;
    font-family: 'calsonproitalic';
    font-size: 17px;
    letter-spacing: 0.02rem;
    line-height: 18px;
    font-style: italic;
    text-align: center;
}

/* Styles pour le carrousel */
.carouselOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.carouselContainer {
    position: relative;
    width: 100%;
    max-width: 900px;
    height: auto;
    overflow: hidden;
    margin: 0px 10px;
    
}


.carouselSlider img {
    width: 100%;
    height: 530px;
}


.slick-prev:before, .slick-next:before {
    content:none;
}

.hotelListContainer, .slick-prev, .slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    
    border: none;
    cursor: pointer;
    z-index: 10;
}


.carouselText {
    position: absolute;
    bottom: 40px;
    left: 15px;
    color: white; 
    font-size: 12px; 
    font-family: 'gustavo';
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    font-weight: 300;
    z-index: 20; 
    opacity: 0; 
    transition: opacity 500ms ease-in-out; 
  }
  
  .carouselText.visible {
    opacity: 1; /* Visible lors de la transition */
  }
  
  
  .carouselTextAdress {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: white; 
    font-size: 13px; 
    font-family: 'calsonproitalic';
    letter-spacing: 0.02rem;
    font-weight: 100;
    font-style: italic;
    z-index: 20; 
    opacity: 0; 
    transition: opacity 500ms ease-in-out; 
  }
  
  .carouselTextAdress.visible {
    opacity: 1; /* Visible lors de la transition */
  }

  .fly{
    position: absolute;
    bottom: 40px;
    right: 15px;
    color: white; 
    font-size: 12px; 
    font-family: 'gustavo';
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    font-weight: 300;
    z-index: 20;
    margin: 0px;
  }
  