.ultraBigContainerResa {
    height: 100vh;
    display: flex;
    justify-content: center;
    background-color: #FEFAF1;
    position: fixed;
    z-index: 99;
    width: 100%;
}


.croixResa {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 100px;
    right: 300px;
}

.ultraBigContainerResa input {
    border: none;
}

.booking-module {
    width: 60%;
    padding: 20px;
    background-color: #FEFAF1;
    margin: 200px;
}

.ligneModule1, .ligneModule2, .ligneModule3, .ligneModule4, .ligneModule5 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.ligneModule1 h3, .ligneModule2 h3, .ligneModule3 h3, .ligneModule4 h3, .ligneModule5 h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
    cursor: pointer;
    width: 30%;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
    height: 38px;
    display: flex;
    align-items: center;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
    font-weight: 500;
    padding-left: 10px;
}

.hotel-list {
    display: none;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.hotel-list.show {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: calc(100% - 2px) !important;
}

.hotel-button {
    padding: 12.5px 0px;
    background-color: #FEFAF1;
    border: 1px solid black;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s;
    width: 70%;
    position: relative;
    display: flex;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;

}

.hotel-button:hover {
    background-color: #f0f0f0;
}

.hotel-button.selected {
    color: black;
}

.calendar-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.calendar {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.calendar-nav {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: black;
}

.calendar-month {
    text-align: center;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
}

.calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.calendar-header {
    text-align: center;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
}

.calendar-day {
    background-color: transparent;
    border: none;
    padding: 8px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
}

.calendar-day:hover {
    background-color: #f0f0f0;
}

.calendar-day.selected:hover {
    background-color: black;
    color: white;
    text-decoration: underline;
}

.calendar-day.selected {
    background-color: black;
    color: white;
    text-decoration: underline;
}

.calendar-day.previous-selected {
    background-color: black;
    color: white;
    text-decoration: underline;
}

.form-group input {
    padding: 10px;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
}

.form-group input[type="number"] {
    width: -webkit-fill-available;
}

.bookingButtonFinal {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    width: 100%;
    margin-top: 20px;
    transition: background-color 0.3s;
}

.bookingButtonFinal:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

.bookingButtonFinal:disabled {
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
}

input[type="text"], input[type="number"] {
    width: -webkit-fill-available;
    padding: 10px;
    font-size: 16px;
}

input[type="text"]:focus, input[type="number"]:focus {
    outline: none;
    border-color: #007bff;
}

.ligneModule1, .ligneModule2, .ligneModule3, .ligneModule4, .ligneModule5 {
    margin-bottom: 0px;
    flex-direction: row;
}

.booking-module>h2 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
}



.bully {
    width: 70%;
    display: flex;
    justify-content: flex-end;
}

.hotel-button svg {
    width: 16px;
    height: 13px;
    position: relative;
    left: 95%;
}

.form-group {
    width: 70%;
}

.conteneurSvg {
    width: 70%;
}


.date-picker-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.calendar-container {
    width: calc(100% - 2px) !important;
    border: 1px solid black;
}

.react-calendar {
    background-color: transparent !important;
    border-style: none !important;
}

.react-calendar--doubleView {
    width: 100% !important;
}

.react-calendar__tile {
    font-family: 'gustavo' !important;
    font-size: 15px !important;
    letter-spacing: .03rem !important;
    text-align: center !important;
    text-transform: uppercase !important;
}

.react-calendar__month-view__weekdays {
    font-family: 'gustavo' !important;
    font-size: 15px !important;
    letter-spacing: .03rem !important;
    text-align: center !important;
    text-transform: uppercase !important;

}

abbr[title] {
    text-decoration: none !important;
}



.react-calendar__tile:disabled:not(.react-calendar__month-view__days__day--neighboringMonth) {
    background-color: transparent !important;
    color: black !important;
    opacity: 0.2 !important;
}

.react-calendar__month-view__days__day--neighboringMonth, .react-calendar__decade-view__years__year--neighboringDecade, .react-calendar__century-view__decades__decade--neighboringCentury {
    opacity: 0 !important;
    pointer-events: none !important;
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
}

.react-calendar__tile--now {
    background-color: transparent !important;
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation button:disabled {
    background-color: transparent !important;
    opacity: 0;
}

.react-calendar__navigation button:hover {
    background-color: transparent !important;
}

.react-calendar__navigation__label {
    pointer-events: none !important;
}

.ligneModule2 {
    flex-direction: row !important;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}


.react-calendar__tile--active {
    background: black !important;
    color: white !important;
    border: none !important;
}

.calendar-day:hover {
    background-color: black !important;
    color: white !important;
}

.react-calendar__navigation__label {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin: auto !important;
}

.react-calendar__navigation__label__labelText {
    font-family: 'gustavo' !important;
    font-size: 15px !important;
    letter-spacing: .03rem !important;
    text-align: center !important;
    text-transform: uppercase !important;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    margin: 0px !important;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    width: 100%;
    margin: 0px !important;
}

.react-calendar__navigation {
    border-bottom: 1px solid black;
    margin-bottom: 0px !important;
}

.react-calendar__month-view {
    border-right: 1px solid black;
}

.react-calendar__navigation *:before {
    border-right: 1px solid black;
}

.ligneModule2 svg {
    width: 16px;
    height: 13px;
    position: relative;
    left: 95%;
}

.ligneModule2 h3 {
    width: 30%;
}

.ligneModule2:hover {
    cursor: pointer;
}

.date-input {
    width: 70%;

}

.calendar-row {
    display: flex;
    width: 100%;

}

.fake {
    opacity: 0;
}

.big-calendar-nav {
    display: flex;
    width: 90%;
    border-bottom: 1px solid black;
    padding: 10px 5%;
    justify-content: space-between;
    align-items: center;
}

.ligneModule2 h3, .ligneModule3 h3, .ligneModule4 h3 {
    border: none;
    border-left: 1px solid black;
    border-right: 1px solid black;
    padding: 5px 0px 5px 10px;
}

.calenderModule {
    width: calc(100% - 1px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
}

.conteneurSvg svg {
    position: relative;
    left: 95%;
}

.ligneModule3 {
    border: 1px solid black;
    border-top: 0px;
}


.list-hotel-calendar {
    border: none;
    width: 98%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;

}

.hotel-list.show {
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

.selected-hotel {
    line-height: 13px;
    padding-left: 18px;
}

.ligneModule4 h3, .ligneModule5 h3, .ligneModule3 h3 {
    border: none;
    border-right: 1px solid black;
}

.ligneModule4, .ligneModule5 {
    border: none;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
    appearance: none;
}

.in-range {
    background-color: black;
    color: white;
    text-decoration: underline;
}

.ultraBigContainerResa .submit-button {
    margin-top: 30px;
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
    text-decoration: underline;
}

.ultraBigContainerResa .submit-button:hover {
    cursor: pointer;
}

.resultDate {
    font-family: 'gustavo';
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.03rem;
}

.calendar-day.disabled {
    pointer-events: none;
    opacity: 0.8;
    /* Applique l'opacité de 80% */
}

@media screen and (max-width:1024px) {
    .booking-module {
        width: 92%;
        padding: 20px 0px;
        margin: 200px 0px;
    }

    .ligneModule1 h3, .ligneModule2 h3, .ligneModule3 h3, .ligneModule4 h3, .ligneModule5 h3 {
        font-size: 13px;
    }

    .calendar-container-departure {
        display: none;
    }

    .calendar-container-arrival {
        width: 100%;
    }

    .croixResa {
        right: 0px;
    }

    .react-calendar {
        width: 100% !important;
    }

    .hotel-button svg {
        left: 90%;
    }

    .ligneModule2 svg {
        left: 90%;
    }

    .voyager-text {
        display: none;
    }

    .hotel-button {
        font-size: 14px;
        color: black;
    }

    .ultraBigContainerResa .submit-button {
        color: black;
    }

    .react-calendar__navigation__label__labelText {
        color: black !important;
    }

    .react-calendar__tile {
        color: black !important;
    }

    .ligneModule1 h3 {
        height: 61px;
    }

    .hotel-button {
        padding: 17.5px 0px;
        height: 26px;
    }

    .list-hotel-calendar {
        padding: 5px 0px;
    }

    .react-calendar__tile--active {
        color: white !important;
    }
}