.containerLanding  .hidden {
    opacity: 0;
    visibility: hidden;
}

.containerLanding .fade-in {
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}




.container{
    height: calc(100vh);
    background-color: #FEFAF1;
    display: flex;
    align-items: flex-end;
    position: relative;

}

.containerLanding {
    width: 100%;
    height: calc(100vh - 127px);
    margin: 0 20px 20px 20px;
    overflow: hidden; 
    position: relative;
    border: 3px solid black; 
}

.imgLandingDesktop {
    width: 100%;
    height: 100%;
    object-fit: cover; 
    position: absolute; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.imgLandingMobile{
    display: none;
}


.citation{
    position: absolute;
    left: 60px;
    bottom: 40px;
    color: white;
    font-family: "calsonpro";
    font-size: 16px;
    letter-spacing: 0.04rem;
    margin: 0px;
}

.volume-controls {
    position: absolute;
    bottom: 40px;
    right: 80px;
    cursor: pointer;
  }

  .volume-icon {
    width: 22px; 
    height: auto;
  }

  @media screen and (max-width: 1600px){

    .citation{
        font-size: 16px;
    }

  }

  @media screen and (max-width: 1600px){

    .citation{
        font-size: 16px;
    }

  }

@media screen and (max-width: 1024px){
    .imgLandingMobile{
        display: block;
        width: calc(100% - 20px);
        height: auto;
        margin: 0px 10px 10px 10px;
    }
    
    .containerLanding{
        display: none;
    }

    .container{
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }

    .container .imgLandingDesktop{
        display: none;
    }

    .volume-controls, .citation{
        display: none;
    }
    .subTitle{
        display: block;
        font-family: "calsonproitalic";
        font-size: 18px;
        letter-spacing: 0.02rem;
        margin: 0px;
        margin-left: 10px;
    }
}

