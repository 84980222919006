.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .containerLogo{
    width: 19%;
  }

  .Sublogo{
    width: 100%;
    height: auto;
  }
  
  
  .fade-in {
    animation: fadeIn 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  

  .navContainer .filter{
    display: flex;
    justify-content: space-between;
    width: 15%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 101;
    color: black;
    transform: translateX(-50%);
}

  .navContainer .filter p {
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    font-family: 'calsonpro';
    padding: 0px;
    margin: 0px;
    width: fit-content;
  }

  .navContainer .filter p.active {
    border-bottom: 1px solid black;
    font-family: 'calsonproitalic';
  }

  .burger-icon svg:hover {
    cursor: pointer;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
  }

  .fr{
    text-decoration: underline;
  }
  
  .navContainer {
    width: 100%;
    z-index: 100;
    position: fixed;
    top: 0;
    background-color: #FEFAF1;
    transition: background-color 0.3s ease;
  }
  
  .navContainer.transparent {
    background-color: rgba(255, 255, 255, 0); /* Ajustez la transparence selon vos besoins */
    transition: background-color 0.3s ease;
  }
  
  .smallNavContainer {
    width: calc(100% - 40px);
    margin: 0px 20px;
    display: flex;
    justify-content: space-between;
    padding: 34px 0px;
  }
  
  .navRight {
    display: flex;
    align-items: center;
    gap: 15px;
  }

  .navRight a{
    align-self: flex-end;
  }
  
  .booking {
    font-family: 'gustavo';
    font-size: 17px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  
  .booking:hover {
    cursor: pointer;
  }
  
  .menu {
    display: none;
    flex-direction: column;
    height: 100vh;
    align-content: space-between;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #FEFAF1;
    z-index: 99;
    position: fixed;
    width: 100%;
  }
  
  .menu.open {
    display: flex;
    background-color: #FEFAF1;
  }
  
  .menu img {
    align-self: flex-start;
  }
  
  .subMenu {
    display: flex;
    flex-direction: column;
    height: 50%;
    width: 100%;
    text-align: center;
    background-color: #FEFAF1;
    justify-content: center;
    margin: auto;
    gap: 10px;
  }
  
  .subMenu .hotel {
    font-size: 40px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'gustavo';
    color: black;
    letter-spacing: 0.02rem;
    margin: 0px;
  }
  
  .subMenu .hotel:hover {
    cursor: pointer;
  }
  
  .subMenu a, .lesHotelsMenu {
    font-size: 40px;
    text-decoration: none;
    text-transform: uppercase;
    font-family: 'gustavo';
    color: black;
    letter-spacing: 0.02rem;
    width: fit-content;
    margin: 0px auto;
  }

  .subMenu a:hover,
  .lesHotelsMenu:hover{
    text-decoration: underline;
    
  }
  
  .bottomLine {
    display: flex;
    width: calc(100% - 40px);
    margin: 0px 20px 20px;
    justify-content: space-between;
  }
  
  .langue {
    display: flex;
    gap: 15px;
  }
  
  .langue button:hover {
    border-bottom: 1px solid black;
    cursor: pointer;
    transition-duration: 300ms;
  }
  
  .langue button:focus {
    border-bottom: 1px solid black;
  }
  
  .langue button {
    font-family: 'gustavo';
    font-size: 14px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  
  .bottomLine .contact {
    font-family: 'gustavo';
    font-size: 17px;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
  }
  
  .hotellist {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .hotellist .hotelLink {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .hotelLink .nextHotelLink {
    font-family: 'calsonpro';
    font-size: 13px;
    font-style: italic;
    letter-spacing: 0.02rem;
  }
  
  .hotellist a {
    font-family: 'calsonpro';
    font-size: 17px;
    text-transform: initial;
  }
  
  .hotellist a,
  .hotelLink .nextHotelLink {
    margin: 0px;
  }
  
  .titleHotelLink {
    font-family: 'gustavo';
    text-transform: uppercase;
    text-align: left;
    letter-spacing: 0.02rem;
    font-size: 14px;
    margin: 0px;
  }
  .submenuLinks{
    display: flex;
      flex-direction: column;
      width: 400px;
  }

  .containerSubMenuLink{
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .ligneSubHotels{
    display: flex;
    justify-content: space-between;
    margin: 2px 0px;
  }
  .ligneSubHotels .ArrowHotels{
    width: 11px;
    height: auto;
  }

  .ligneSubHotels a{
    font-family: 'calsonpro';
    font-size: 17px;
    text-transform: inherit;
    text-align: left;
    margin: 0px;
  }

  .ligneSubHotels p{
    font-family: 'calsonproitalic';
    font-size: 15px;
    text-transform: inherit;
    margin:0px;
  }

  .lesHotelsRedirec{
    letter-spacing: 0.01rem !important; 
    font-family: 'gustavo'!important;
    font-size: 14px !important;
    text-align: left;
    margin: 0px !important;
    display: flex;
    width: 100%;
    margin-bottom: 10px !important;
  }
  
  .hotelMobileMenu{
    display: none;
  }
  @media screen and (max-width: 1024px) {
    .hotelMobileMenu{
      display: block;
    }
    .lesHotelsMenu{
      display: none;
    }
    .ligneSubHotels{
      margin: 5px 0px;
    }

    .containerLogo{
      width: 70%;
    }
    .Sublogo,
    .booking {
      display: none;
    }

    .navContainer .filter{
      display: none;
  }
  
    .smallNavContainer {
      padding-top: 20px;
      margin-left: 10px;
    }
  
    .Logo {
      width: 100%;
      height: auto;
    }
  
    .burger-icon svg {
      width: 30px;
      height: 20px;
    }
  
    .bottomLine {
      justify-content: flex-end;
    }
  
    .bottomLine .langue {
      flex-direction: column-reverse;
    }
  
    .bottomLine .contact {
      display: none;
    }
  
    .subMenu {
      gap: 25px;
    }
  
    .subMenu .hotel,
    .subMenu a,
    .lesHotelsMenu {
      font-size: 26px;
    }
  
    .titleHotelLink {
      font-size: 12px;
    }
  
    .hotellist a {
      font-size: 12px;
    }
  
    .hotelLink .nextHotelLink {
      font-size: 10px;
    }
  
    .hotelLink img {
      width: 10px;
      height: auto;
    }

    .ligneSubHotels a{
      font-family: 'calsonpro';
      font-size: 14px;
      text-transform: inherit;
      text-align: left;
      margin: 0px;
    }
  
    .ligneSubHotels p{
      font-family: 'calsonproitalic';
      font-size: 13px;
      text-transform: inherit;
      margin:0px;
    }

    .submenuLinks{
      width: 370px;
    }
  }
  