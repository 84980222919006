body, html {
  overflow: hidden;
  height: 100%;
  margin: 0;
  position: relative;
}

/* App.css */

.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 1;
  transform: translateY(100%);
  opacity: 0;
}

.section.active {
  transform: translateY(0);
  z-index: 2;
  opacity: 1;
}

.section.fixed {
  position: fixed;
  transform: translateY(0);
  z-index: 1;
  opacity: 1;
}

/* La première section est toujours visible au début */
.section:first-child {
  transform: translateY(0);
  z-index: 1; /* On s'assure que la première section est au-dessus par défaut */
  opacity: 1;
}



@font-face {
  font-family: 'gustavo';
  src: url('./fonts/Gustavo-Medium.woff') format('woff');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'gustavoreg';
  src: url('./fonts/Gustavo-Regular.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'calsonbold';
  src: url('./fonts/AdobeCaslonProBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'calsonpro';
  src: url('./fonts/ACaslonPro-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'calsonproitalic';
  src: url('./fonts/AdobeCaslonProItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'calsonprobolditalic';
  src: url('./fonts/acaslonpro-bolditalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}